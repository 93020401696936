.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-image: linear-gradient(-45deg, #f0f2f5, var(--white));
    // background-color: #f0f2f5;
    //height: 100vh;
}

.container {
    margin-top: calc(var(--default-layout-header-height) + 24px);
    width: 100%;
    max-width: var(--default-layout-width);
    display: flex;
}

.content {
    flex: 1;
}
