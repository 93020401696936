.account-item {
    padding: 9px 16px;
    width: 100%;
    height: 60px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    &:hover {
        background-color: #f5f5f5;
    }
}
.avatar {
    width: 40px;
    height: 40px;
    display: inline-block;
    box-sizing: border-box;
    margin: 0px 12px 0px 0px;
    padding: 0px;
    font-feature-settings: 'tnum';
    position: relative;
    overflow: hidden;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    border-radius: 50%;
    background-color: rgba(136, 136, 136, 0.5);
}
.info-nickName {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.info-tick {
    margin-left: 8px;
    color: var(--primary);
}
.info-fullName {
    font-size: 14px;
    line-height: 20px;
    color: rgba(22, 24, 35, 0.5);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
