.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    height: 200px;
    align-content: center;
}

.card {
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: #f0f2f5 !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
}
.loading {
    font-size: 20px;
    color: white;
    animation: spinner 0.8s linear infinite;
}
@keyframes spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@media only screen and (max-width: 500px) {
    .card {
        width: 300px;
    }
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: var(--primary);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-body {
    display: flex;
    flex-direction: column;
    // flex: 1 1 auto;
    height: 100%;
    padding: 1.25rem;
}

.errors {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    color: #bd1324;
    padding-left: 12px;
    height: 28px;
    font-weight: 700;
    font-size: 20px;
}
.alert-login-fail {
    padding-left: 12px;
}
.input-from {
    height: 100%;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 32px;
    width: 100%;
    margin-bottom: 1rem;
}
.input-group-prepend {
    margin-right: -1px;
    display: flex;
}
.form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    flex: 1 1 auto;

    margin-bottom: 0;
    display: block;

    height: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    // line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    margin-left: 10px;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus-within {
        border-color: var(--primary);
    }
}
.login_btn {
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    color: white;
    background-color: var(--primary);
    cursor: pointer;

    float: right;

    border: none;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 16px;
    margin-top: 16px;
    width: 100px;
    &.disabled {
        background-color: var(--font-color-disable);
    }
}
.social_icon span:hover {
    color: white;
    cursor: pointer;
}

.card-header h3 {
    color: white;
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend {
    width: 32px;
    background-color: var(--primary);
    color: var(--white);
    border: 0 !important;
    border-radius: 5px;
}
.input-group-text {
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
    display: flex;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn:hover {
    border-color: var(--primary);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--primary);
    &.disabled {
        background: var(--font-color-disable);
    }
}

.links {
    color: white;
}

.links a {
    margin-left: 4px;
}
.alert-danger {
    color: #bd1324;
    font-weight: 600;
}
