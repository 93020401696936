.circle-in {
    position: absolute;
    height: 40px;
    width: 40px;
    box-shadow: inset 0 0 0 3.6px rgb(224, 224, 224);
    border-radius: 50%;
    display: inline-block;
}
.box-content {
    &:hover {
        background-color: transparent !important;
    }
}
// .success {
//     box-shadow: inset 0 0 0 3.6px rgb(160, 202, 162);
// }
// .warning {
//     box-shadow: inset 0 0 0 3.6px #e9a641;
// }
// .error {
//     box-shadow: inset 0 0 0 3.6px #dd6a68;
// }
