.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    padding: 9px 16px;
    border-radius: 4px;
    font-family: var(--main-font);
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid transparent;
    user-select: none;

    + .wrapper {
        margin-left: 8px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon + .title,
.title + .icon {
    margin-left: 8px;
}

.icon {
    display: flex;
    height: 20px;
    width: 20px;
    text-align: center;
    align-items: center;
}

// Button types
.rounded {
    border-radius: 999px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        background-color: rgba(22, 24, 35, 0.03);
    }
}

.round {
    border-radius: 5px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        background-color: rgba(22, 24, 35, 0.03);
    }
}

.primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
        border-color: var(--primary);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--primary);
    }
}

.outline {
    color: var(--primary);
    border-color: currentColor;

    &:hover {
        border-color: currentColor;
        background-color: rgba(254, 44, 85, 0.06);
    }
}

.text {
    background-color: transparent;
    &:hover {
        text-decoration: underline;
    }
}

// Disabled

// Button sizes
.small {
    min-width: 88px;
    padding: 4px 16px;
}

.large {
    padding: 14px 16px;
    min-width: 140px;
}
