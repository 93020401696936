.total {
    margin-top: 8px;
    margin-right: 16px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    float: right;
}
.floatRight {
    text-align: right;
}
.button {
    margin-top: 4px;
    align-self: end;
}
.textUnderline {
    text-decoration: underline;
    text-underline-offset: 4px;
}
.reported {
    width: 100%;
    height: 36px;
    margin-top: 12px;
    margin-left: 16px;
    display: flex;
}
