.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 0px auto;
    padding: 0px 24px;
    box-sizing: content-box;
    //margin-bottom: 24px;
    //min-height: calc(100vh - 59px);
}
.ImgProfile {
    width: 100%;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
}
.DivShareInfo {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    background-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
}
.DivContainer {
    position: relative;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SpanAvatarContainer {
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-feature-settings: 'tnum';
    position: relative;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    line-height: 32px;
    border-radius: 50%;
    background-color: rgba(136, 136, 136, 0.5);
    border-color: rgba(22, 24, 35, 0.12);
    border-width: 0.5px;
    //  cursor: pointer;
}
.DivContainerAvatar {
    margin-top: -50%;
}
.ImgAvatar {
    width: 124px;
    height: 124px;
    object-fit: cover;
    border: 4px solid var(--white);
    border-radius: 50%;
    &.editmode {
        box-shadow: 0px 0.3px 0.9px var(--bg-shadow), 0px 1.6px 3.6px var(--bg-shadow);
    }
}
.ImgProfile {
    box-shadow: 0px 0.3px 0.9px var(--bg-shadow), 0px 1.6px 3.6px var(--bg-shadow);
}
.DivAvatarEditIcon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(208, 208, 211);
    box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    margin-top: 90px;
    margin-left: 80px;
    color: var(--primary);
    &.DivProfileEditIcon {
        margin-top: 184px;
        margin-left: 480px;
    }
}
.info-tick {
    width: 24px;
    height: 24px;
    padding: 1px;
    cursor: default;
}
.InputUpload {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
}
.DivShareTitleContainer {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0%;
    //cursor: pointer;
    overflow: visible;
    margin-left: 20px;
}
.H2ShareTitle {
    margin-top: 8px;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    // padding-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    cursor: default;
}
.H1ShareSubTitle {
    font-family: 'IBM Plex Sans', ProximaNova, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-overflow: ellipsis;
    height: 25px;
    overflow: hidden;
    max-width: 450px;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    cursor: default;
}
.DivEditContainer {
    position: relative;
    width: 136px;
    height: 36px;
    padding: 0px;
    margin: 32px 16px 0px;
}
.linebreak {
    width: 100%;
    height: 1px;
    background-clip: content-box;
    background-color: rgba(136, 136, 136, 0.5);
}

.text {
    font-family: var(--main-font);
    font-weight: 700;
}
//Dialog Edit
.Dialog-tittle {
    width: 552px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.editAvatar {
    // display: flex;
    // height: 100%;
    // position: absolute;
    // width: 552px;
    // &::before {
    //     content: 'heelo';
    //     height: 150px;
    // }
}
.close-icon {
    cursor: pointer;
}
//Avatar component

.AvatarWrapper {
    display: flex;
    justify-content: center;
}
.body-wrapper {
    padding: 24px;
}
@media only screen and (max-width: 850px) {
    .H2ShareTitle {
        margin-top: 4px;
        font-size: 24px;
        line-height: 32px;
    }
    .H1ShareSubTitle {
        font-size: 16px;
        padding-bottom: 4px;
    }
    .ImgAvatar {
        height: 96px;
        width: 96px;
    }
    .DivAvatarEditIcon {
        margin-top: 70px;
        margin-left: 65px;
        width: 20px;
        height: 20px;
    }
    .DivEditContainer {
        display: none;
    }
    .linebreak {
        margin-top: 0px !important;
    }
    .ImgProfile {
        height: 100px;
    }
}
@media only screen and (max-width: 450px) {
    .H2ShareTitle {
        margin-top: 4px;
        font-size: 20px;
        line-height: 28px;
    }
    .H1ShareSubTitle {
        font-size: 14px;
        padding-bottom: 4px;
    }
    .ImgAvatar {
        height: 80px;
        width: 80px;
    }
    .DivAvatarEditIcon {
        margin-top: 60px;
        margin-left: 55px;
        width: 16px;
        height: 16px;
    }
    .DivShareTitleContainer {
        margin-left: 8px;
    }
}
