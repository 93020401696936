.section-default {
    width: 100%;
    height: 16px;
    position: fixed;
    z-index: 10;
    cursor: default;
}
.wrapper {
    display: flex;
    height: 100%;
    background-color: var(--primary);
    justify-content: center;
}
.inner {
    height: 100%;
    width: var(--default-layout-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-item {
    display: flex;
    color: white;
    padding: 4px;
}
@media screen and (max-width: 500px) {
    .top-item.right {
        display: none !important;
    }
}
.icons {
    margin-right: 12px;
    padding-top: 4px;
}
// .inner::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -10px;
//     width: 30%;
//     height: 16px;
//     transform: skewX(-25deg);
//     z-index: 1;
//     background: var(--primary);
// }
