// Menu wrapper
.menu-list {
    width: 224px;

    .menu-item {
        margin-left: 0;
    }
}

.menu-popper {
    padding-bottom: 8px;
}

// Menu items
.menu-item {
    width: 100%;
    justify-content: flex-start;
    border-radius: 0;
    padding: 11px 16px;
    line-height: 1.8rem;
    font-weight: 600;

    &.separate {
        border-top: 1px solid rgba(22, 24, 35, 0.12);
    }
    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }
}

// Header
.header {
    //display: flex;
    // flex: 0 0 50px;
    height: 50px;
    flex-shrink: 0;
    position: relative;
    margin-top: -8px;
    &::after {
        content: '';
        position: absolute;
        top: 50px;
        right: 0;
        height: 1px;
        width: 100%;
        background-color: rgba(22, 24, 35, 0.12);
    }
}

.back-btn {
    width: 50px;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
}

.header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu-body {
    // max-height: min((100vh - 96px) - 110px, 734px);
    flex: 1;
    overflow-y: auto;
}
