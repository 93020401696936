.Tab {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}

.Tab.Mui-selected {
    color: var(--primary);
}

.MuiTabs-indicator {
    background-color: var(--primary);
}
.orderWrapper {
    display: flex;
    align-items: center;
    cursor: default;
}
.list {
    user-select: none;
    display: flex;
    justify-content: flex-start;
}

.item {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    color: white;
    margin: 8px;
    height: 24px;
    cursor: grab;
    padding: 4px;
    border-radius: 4px;
    font-size: 14px;
}

.dragged {
    background-color: rgb(37, 37, 197);
}
@media only screen and (max-width: 500px) {
    .orderWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: default;
    }
    .item {
        margin: 4px;
    }
}
