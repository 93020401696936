:root {
    --bg-primary: #fff;
    --bg-secondary: #faf9f8;
    --bg-tertiary: #fff;
    --bg-primary-accent: #faf9f8;
    --bg-hover: #f5f5f5;
    --bg-disabel: #ebebeb;
    --bg-hover-secondary: #fdfdfd;
    --bg-hover-tertiary: #f5f5f5;
    --bg-active: #eff6fc;
    --bg-active-secondary: #f3f2f1;
    --bg-active-tertiary: #f3f2f1;
    --bg-separator: #e1dfdd;
    --bg-border: #e1dfdd;
    --bg-scrollbar: #edebe9;
    --bg-shadow: rgba(0, 0, 0, 0.1);
    --bg-brand: #2564cf;
    --bg-brand-hover: #215aba;
    --bg-brand-hover-secondary: #8c93a5;
    --bg-brand-secondary: #ecf6fd;
    --bg-brand-disable: #b7d7f6;
    --font-color-primary: #292827;
    --font-color-secondary: #605e5c;
    --font-color-tertiary: #605e5c;
    --font-color-warning: #a80000;
    --font-color-brand: #2564cf;
    --font-color-disable: #a19f9d;
    --bg-ms-planner: #31752f;
    --bg-ms-one-note: #7719aa;
    --bg-ms-excel: #107c41;
    --bg-ms-word: #185abd;
    --bg-ms-power-point: #c43e1c;
    --bg-ms-teams: #6355a8;
    --bg-warning: #fff4ce;
    --bg-error: #fed9cc;

    --badge-width: 44px;
    --badge-height: 26px;

    --error-color: rgb(211, 47, 47);
    --error-text-color: rgb(95, 33, 32);
    --error-color-hover: rgb(249, 232, 232);
    --error-color-bg: rgb(253, 237, 237);
    --success-color: rgb(46, 125, 50);
    --success-text-color: rgb(30, 70, 32);
    --success-color-hover: rgb(232, 242, 232);
    --success-color-bg: rgb(237, 247, 237);
    --warning-color: rgb(237, 108, 2);
    --warning-text-color: rgb(102, 60, 0);
    --warning-color-hover: rgb(250, 239, 224);
    --warning-color-bg: rgb(255, 244, 229);
}
.taskItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: slideFadeDown 250ms forwards;
    :hover {
        background-color: var(--bg-hover);
    }
}
.reported {
    cursor: default;
    :hover {
        cursor: default;
        background: var(--bg-primary);
    }
}
.taskItem-body {
    background-color: var(--bg-primary);
    box-shadow: 0px 0.3px 0.9px rgb(0 0 0 / 10%), 0px 1.6px 3.6px rgb(0 0 0 / 10%);
    box-shadow: 0px 0.3px 0.9px var(--bg-shadow), 0px 1.6px 3.6px var(--bg-shadow);
    margin-top: 8px;
    border-radius: 4px;
    padding: 0px 16px;
    min-height: 52px;
    display: flex;
    align-items: center;
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
    flex-wrap: wrap;
    &.disabled {
        cursor: default;
        background-color: var(--bg-disabel);
    }
}
.checkBox {
    padding: 4px;
    cursor: pointer;
    height: 48px;
    width: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.reported {
        cursor: default;
    }
}
.checkBox:hover {
    .hideOnhover {
        display: none;
        &.iconsDisabled {
            display: inline;
        }
    }
    .showOnhover {
        display: inline;
        &.iconsDisabled {
            display: none;
        }
    }
}
.checkBox.completed {
    animation: checkAnimationBase 100ms;
}
@keyframes slideFadeDown {
    0% {
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.taskItem-titleWrapper {
    font-size: 1.4rem;
    background: none;
    color: #323130;
    color: var(--font-color-primary);
    position: relative;
    flex: 1 1 0px;
    min-height: 52px;
    padding: 8px 14px;
    line-height: 2rem;
    overflow: hidden;
    cursor: pointer;
    font-family: var(--main-font);
    font-size: 1.6rem;
    font-weight: 700;
    &.disabled {
        cursor: default;
    }
}
.tittle-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    //margin-right: auto;
}
.jobInfo {
    display: flex;
    align-items: center;
}
.completed {
    text-decoration: line-through;
}
.fluentIcon {
    color: #2564cf;
    &.iconsDisabled {
        color: #fff;
        cursor: default;
    }
    &.reported {
        cursor: default;
    }
}
.Input {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
}
.showOnhover {
    display: none;
}
.taskItem-titleWrapper {
    text-align: left;
    .reported {
        cursor: default;
    }
}
.importanceButton {
    cursor: pointer;
}
.span-center {
    display: flex;
    justify-items: center;
}
.coin {
    color: var(--primary);
    margin-right: 4px;
}
.span-deadline {
    // width: 100px;
    padding: 0px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--font-color-primary);
}
.badgeWrapper {
    position: absolute;
    border-left: calc(var(--badge-width) / 2) solid transparent;
    border-right: calc(var(--badge-width) / 2) solid transparent;
    border-top: calc(var(--badge-height) / 2) solid transparent;
    border-bottom: calc(var(--badge-height) / 2) solid transparent;
    border-top-left-radius: 5px;

    width: 0px;
    height: 0px;
    top: 18px;
    left: 10px;
}
.badgeItem {
    margin-top: -14px;
    margin-left: -23px;
    color: var(--bg-hover);
}
.badge-job-success {
    border-left-color: var(--success-color);
    border-top-color: var(--success-color);
}
.success {
    color: var(--success-text-color);
    :hover {
        background-color: var(--success-color-hover);
    }
    .reported {
        background-color: var(--success-color-bg);
        :hover {
            background-color: var(--success-color-bg);
        }
        cursor: default;
    }
    .disabled {
        background-color: var(--bg-disabel);
        :hover {
            background-color: var(--bg-disabel);
        }
    }
}
.badge-job-error {
    border-left-color: var(--error-color);
    border-top-color: var(--error-color);
}
.error {
    color: var(--error-text-color);
    :hover {
        background-color: var(--error-color-hover);
    }
    .reported {
        background-color: var(--error-color-bg);
        :hover {
            background-color: var(--error-color-bg);
        }
        cursor: default;
    }
    .disabled {
        background-color: var(--bg-disabel);
        :hover {
            background-color: var(--bg-disabel);
        }
    }
}
.badge-job-warning {
    border-left-color: var(--warning-color);
    border-top-color: var(--warning-color);
}
.warning {
    color: var(--warning-text-color);

    :hover {
        background-color: var(--warning-color-hover);
    }
    .reported {
        background-color: var(--warning-color-bg);
        :hover {
            background-color: var(--warning-color-bg);
        }
        cursor: default;
    }
    .disabled {
        background-color: var(--bg-disabel);
        :hover {
            background-color: var(--bg-disabel);
        }
    }
}
.badge-root {
    :hover {
        background-color: transparent;
    }
}
.circularProgress {
    :hover {
        background-color: transparent;
    }
}
.disabled :not(.reported) {
    :hover {
        background-color: var(--bg-disabel);
    }
}
.disabled {
    :hover {
        background-color: var(--bg-disabel);
    }
}
.disabled .badge-root {
    :hover {
        background-color: transparent;
    }
}
@media only screen and (max-width: 500px) {
    .checkBox {
        //display: none;
        padding: 0px;
    }
    .taskItem-body {
        display: flex;
        // flex-direction: column;
        padding: 0px 8px;
    }
    // .taskItem-titleWrapper {
    //     padding: 8px 4px;
    // }
    .jobInfo {
        order: 1;
        flex: 1 0 100%;
        justify-content: center;
        margin-bottom: 8px;
    }
}
