.wrapper {
    --search-border-radius: 92px;
    --search-height: 46px;
    --search-top-spacer: 9px;
    --search-button-width: 52px;
    --line-color: rgb(0 0 0 / 12%);

    width: 100%;
    min-width: var(--default-layout-min-width);
    height: var(--default-layout-header-height);
    box-shadow: 0px 2px 2px var(--line-color);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 16px;
    background-image: linear-gradient(-45deg, #f0f2f5, var(--white));
    background-color: #f0f2f5;
    z-index: 10;
    // background-color: rgba(255, 255, 255, 0.475);
    // backdrop-filter: blur(10px);
}

.inner {
    height: 100%;
    width: var(--default-layout-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    // margin-top: var(--default-layout-header-height);
    margin-left: 20px;
    padding: 5px;
    margin-top: 3px;
    // border: 1px solid;
    //border-radius: 3px;
    // border-color: var(--line-color);
    height: calc(var(--default-layout-header-height) + 5px);
}

.acc-title {
    height: 30px;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
}

// Actions
.actions {
    display: flex;
    align-items: center;
    padding-right: 20px;
}
.DivShareTitleContainer {
    //  flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    cursor: default;
    float: left;
    overflow: visible;
    margin-right: 4px;
}
.H2ShareTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    padding: 4px 0 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    text-shadow: 1px 1px var(--line-color);
}
.H1ShareSubTitle {
    font-family: 'IBM Plex Sans', ProximaNova, Arial, Tahoma, PingFangSC, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-overflow: ellipsis;
    height: 22px;
    overflow: hidden;
    max-width: 450px;
    white-space: nowrap;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    padding-right: 8px;
}
.action-btn {
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    display: flex;
    font-size: 2.2rem;
    color: #161823;
    background-color: transparent;
    padding: 4px;
    cursor: pointer;
}

.badge {
    position: absolute;
    top: -3px;
    right: -5px;
    padding: 0px 6px;
    height: 2rem;
    line-height: 2rem;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--white);
    font-family: var(--main-font);
    background-color: var(--white);
}
.div-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-avatar {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
    border: 1px solid;
    border-color: var(--white);
    box-shadow: 1px 1px var(--line-color);
}
.div-info-tick {
    position: absolute;
}
.info-tick {
    position: absolute;
    top: 11px;
    left: 15px;
    color: var(--primary);
    border: 2px solid;
    border-radius: 50%;
    border-color: var(--white);
}
.more-btn {
    font-size: 2rem;
    margin-left: 28px;
    padding: 4px 8px;
    background-color: transparent;
    &:hover {
        cursor: pointer;
    }
}
@media only screen and (max-width: 850px) {
    .searchDiv {
        display: none;
    }
}
@media only screen and (max-width: 500px) {
    .logo {
        margin-left: 4px;
        //display: none;
    }
    .user-avatar {
        width: 40px;
        height: 40px;
        margin-left: 4px;
    }
    .actions {
        padding-right: 8px;
    }
    .H2ShareTitle {
        font-size: 16px;
        line-height: 16px;
    }
    .H1ShareSubTitle {
        font-size: 12px;
    }
    .info-tick {
        top: 6px;
        left: 6px;
    }
}
