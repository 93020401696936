.tippy-box[data-theme~='light'] {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    color: #26323d;
    box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25),
        0 4px 4px -2px rgba(91, 94, 105, 0.15);
    background-color: #fff;
}
.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow:before {
    border-top-color: #fff;
}
.tippy-box[data-theme~='light'][data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: #fff;
}
.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow:before {
    border-left-color: #fff;
}
.tippy-box[data-theme~='light'][data-placement^='right'] > .tippy-arrow:before {
    border-right-color: #fff;
}
.tippy-box[data-theme~='light'] > .tippy-backdrop {
    background-color: #fff;
}
.tippy-box[data-theme~='light'] > .tippy-svg-arrow {
    fill: #fff;
}
