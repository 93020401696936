@import 'normalize.css';

:root {
    // --primary: #fe2c55;
    --primary: #366cf4;
    --black: #000;
    --white: #fff;
    --text-color: #161823;
    --default-layout-header-height: 70px;
    --default-layout-width: 1150px;
    --default-layout-min-width: 375px;
    --main-font: 'ProximaNova', sans-serif;
}
button,
input,
[tabindex] {
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
}

a {
    color: var(--text-color);
    text-decoration: none;
}
//Font Embedded
@font-face {
    font-family: ProximaNova;
    src: url(/assets/fonts/Proxima-Nova-Regular.woff2);
    font-weight: 400;
}
@font-face {
    font-family: ProximaNova;
    src: url(/assets/fonts/Proxima-Nova-Semibold.woff2);
    font-weight: 600;
}
@font-face {
    font-family: ProximaNova;
    src: url(/assets/fonts/ProximaNova-Semibold.woff2);
    font-weight: 700;
}
@font-face {
    font-family: SofiaPro;
    src: url(/assets/fonts/sofiapro-semibold.otf);
    font-weight: 700;
}
@font-face {
    font-family: IBM Plex Sans;
    src: url(/assets/fonts/IBM_Plex.woff2);
    font-weight: 700;
}
// Custom tippy tooltip
body {
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;

        background-color: rgba(84, 84, 84, 0.92);
    }
    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }

    .tippy-content {
        padding: 8px 9px;
    }

    .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -8px;
    }
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
}

body {
    color: var(--text-color);
    font-family: var(--main-font);
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizespeed;
    overflow: overlay;
    background-color: #f9fafc;
    // background-color: rgba(0, 0, 0, 0.25);
    // background-image: url(/assets/images/BackGround.png);
    // background-repeat: repeat-y;

    // background-color: transparent;
}
// body:after {
//     content: '';
//     //display: block;
//     position: absolute;
//     top: 0;
//     left: 0;
//     // background-image: url(/assets/images/BackGround.png);
//     background-size: auto;
//     width: 100%;
//     height: 100%;

//     z-index: -1;
// }
//scrollbar
// body::-webkit-scrollbar {
//     width: 16px;
// }

// body:not([no-y-overflow])::-webkit-scrollbar-thumb {
//     height: 56px;
//     border-radius: 8px;
//     border: 4px solid transparent;
//     background-clip: content-box;
//     background-color: var(--yt-spec-text-secondary);
// }

// body::-webkit-scrollbar-thumb {
//     height: 56px;
//     border-radius: 8px;
//     border: 4px solid transparent;
//     background-clip: content-box;
//     background-color: hsl(0, 0%, 67%);
// }
html ::-webkit-scrollbar {
    border-radius: 0px;
    width: 8px;
}

html ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, 0.06);
}

html ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
}
