.tittle {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
}

.content p {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    width: 550px;
}

.content label {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}
.content input {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    // padding: 0px !important;
    margin-top: 2px;
    margin-left: 14px;
}

.wrapper button {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 16px;
}

.wrapper {
    width: 100%;
    padding: 0px;
}
.wrapper-autocomplete {
    width: 116px;
    margin-left: 10px;
    padding: 0px;
    & button {
        right: 0px;
        & svg {
            width: 20px;
            height: 20px;
        }
    }
}
