/* .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 16px;
} */
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -4px;
} */
/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    top: 4px;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    top: 4px;
} */
/* .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 16px;
} */

/* .css-1q60rmi-MuiAutocomplete-endAdornment {
    right: 4px;
} */
/* .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px;
} */
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
} */
/* snackbar */
/* .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
} */

.MuiInputBase-root.MuiAutocomplete-inputRoot {
    font-size: 16px;
}
.MuiFormLabel-root.MuiInputLabel-root {
    top: -4px;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
    top: 4px;
}
.MuiOutlinedInput-root.MuiAutocomplete-inputRoot > .MuiAutocomplete-endAdornment {
    right: 4px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding-right: 34px;
}
.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    padding: 0px;
}
.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
}
/* snackbar */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
    font-family: var(--main-font);
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}
